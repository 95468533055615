import Image from 'next/image';


type Props = {
  title: string;
  file: string;
  width: number;
  height: number;
}

function ImageStreamfield(props: Props) {


  const { title,
    file,
    width,
    height, } = props;

  return (
    <div className='streamfield image flow'>
      <Image
        src={file}
        alt={title}
        width={width}
        height={height}
        style={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover'
        }}

      />
    </div>
  );
}

export default ImageStreamfield;
