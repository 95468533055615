import React, { FunctionComponent } from 'react';
import BasePage from './BasePage';

import type { Props as BasePageProps } from './BasePage';

export { BasePageProps };

export const basePageWrap = <Props extends BasePageProps>(Component: FunctionComponent<Props>) => {
  const displayName = Component.displayName ?? Component.name ?? 'Component';

  function WrappedBasePage(props: Props) {
    return (
      <BasePage {...props} _class={Component.name}>
        <Component {...props} />
      </BasePage>
    );
  }

  WrappedBasePage.displayName = `basePageWrap(${displayName})`;

  return WrappedBasePage;
};

export default BasePage;
