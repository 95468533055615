import { FormField } from '@/types';
import Input from '@components/Form/Input';
import React from 'react';

function DateTimeField(props: FormField) {
  const { label, clean_name: cleanName, help_text: helpText } = props;

  return <Input label={label} type='datetime-local' id={cleanName} placeholder={helpText} />;
}

export default DateTimeField;
