import clsx from 'clsx';
import React, { ButtonHTMLAttributes } from 'react';

type Props = {
  primary?: boolean;
  secondary?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { primary, secondary, className, ...rest } = props;

  return (
    <button
      className={clsx(
        'button',
        {
          'button--primary': primary,
          'button--secondary': secondary,
        },
        className,
      )}
      ref={ref}
      {...rest}
    />
  );
});

Button.displayName = 'Button';

export default Button;
