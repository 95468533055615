import Link from 'next/link';

import { removeBaseUrl } from '@/utils/url';

type Props = {
  button_page: { title: string; full_url: string } | null;
  button_url: string | null;
  button_text: string;
};

function CTA(props: Props) {
  const { button_page: buttonPage, button_url: buttonUrl, button_text: buttonText } = props;

  return (
    <div className='streamfield cta'>
      {buttonPage ? (
        <Link href={removeBaseUrl(buttonPage.full_url)} legacyBehavior>
          {buttonText}
        </Link>
      ) : null}
      {buttonUrl ? (
        <Link href={buttonUrl} legacyBehavior>
          {buttonText}
        </Link>
      ) : null}
    </div>
  );
}

export default CTA;
