import { removeBaseUrl } from '@/utils/url';
import Link from 'next/link';

import { WagtailPage } from '@/types';

type Button = {
    primary: boolean;
    button_text: string;
    button_page: WagtailPage | null;
    button_url: string | null;
};

type Props = {
    buttons: Button[];
};

function ButtonBlock(props: Props) {
    const { buttons } = props;
    return (
        <div className='streamfield buttons'>
            {buttons.map(button => (
                <Link
                    key={button.button_text}
                    href={
                        button.button_page
                            ? removeBaseUrl(button.button_page.full_url)
                            : button.button_url ?? ''
                    }
                    passHref
                    className={button.primary ? 'button button--primary' : 'button'}
                >
                    {button.button_text}
                </Link>
            ))}
        </div>
    );
}

export default ButtonBlock;