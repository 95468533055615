import React, { InputHTMLAttributes } from 'react';
import Label from '../Label';

type Props = { label: string; id: string };

const Input = React.forwardRef<HTMLInputElement, Props & InputHTMLAttributes<HTMLInputElement>>(
  (props, ref) => {
    const { label, ...rest } = props;
    return (
      <div className='input'>
        <Label htmlFor={rest.id} text={`${label}${!rest.required ? ' (optional)' : ''}`} />
        <input ref={ref} {...rest} name={rest.id} className='input__element' />
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
