import ArticleTeaser from './ArticleTeaser';
import BigQuote from './BigQuote';
import ButtonBlock from './ButtonBlock';
import CTA from './CTA';
import CodeBlock from './CodeBlock';
import ContentMarketing from './ContentMarketing';
import Download from './Download';
import DownloadGroup from './DownloadGroup';
import HighlightQuote from './HighlightQuote';
import ImageStreamfield from './ImageStreamfield';
import Links from './Links';
import Message from './Message';
import Richtext from './Richtext';
import Sources from './Sources';
import Table from './Table';

const streamfields = {
  cta: CTA,
  links: Links,
  message: Message,
  full_richtext: Richtext,
  simple_richtext: Richtext,
  table: Table,
  code: CodeBlock,
  article_teaser: ArticleTeaser,
  quote: BigQuote,
  download: Download,
  highlight: HighlightQuote,
  downloads: DownloadGroup,
  image: ImageStreamfield,
  sources: Sources,
  button: ButtonBlock,
  content_marketing: ContentMarketing,
};

export type Streamfields = keyof typeof streamfields;

export default streamfields;
