import { WagtailArticlePageSummary, WagtailAuthorImage } from '@/types';
import RawHtml from '@components/RawHtml';
import { removeBaseUrl } from '@utils/url';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

type Props = {
  article: WagtailArticlePageSummary;
  arrangement: 'two_thirds_left' | 'two_thirds_right' | 'full_width' | 'half_width';
  unrelatedImage: WagtailAuthorImage | null;
};

function ArticleTeaser(props: Props) {
  const { article, arrangement, unrelatedImage } = props;

  return (
    <div className='streamfield article_teaser flow copy_type'>
      <Link href={removeBaseUrl(article.full_url)} className='teaser__link'>
        <div className='content_wrapper'>
          <div
            className={clsx('teaser_text', {
              'teaser--1-2': arrangement === 'two_thirds_left',
              'teaser--2-1': arrangement === 'two_thirds_right',
              'teaser--1': arrangement === 'full_width',
              'teaser--1-1': arrangement === 'half_width',
            })}
          >
            <h2>{article.title}</h2>
            <RawHtml html={article.teaser} />
            <div className='readmore_container'>
              <p className='readmore_link'>Weiterlesen …</p>
            </div>
          </div>

          {arrangement !== 'full_width' && (
            <div className='article_image'>
              {unrelatedImage != null ? (
                <Image
                  src={unrelatedImage.file}
                  alt='Placeholder'
                  width={700}
                  height={700}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <Image
                  src={article.primary_image.file}
                  alt='Placeholder'
                  width={700}
                  height={700}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                  }}
                />
              )}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

export default ArticleTeaser;
