import { FormField } from '@/types';
import Input from '@components/Form/Input';
import React from 'react';

function DateField(props: FormField) {
  const {
    label,
    clean_name: cleanName,
    help_text: helpText,
    default_value: defaultValue,
    required,
  } = props;

  return (
    <Input
      label={label}
      type='date'
      id={cleanName}
      placeholder={helpText}
      defaultValue={defaultValue || new Date().toISOString().split('T')[0]}
      required={required}
    />
  );
}

export default DateField;
