import { WagtailQuoteSnippet } from "@/types";
import RawHtml from "@components/RawHtml";
import clsx from "clsx";

type Props = {
  background: { choices: "pink" | "blue" | "yellow" | "green" | "orange" };
  quote: WagtailQuoteSnippet;
};

function BigQuote(props: Props) {
  const { background, quote } = props;

  return (
    <div
      className={clsx("streamfield", "big-quote", "flow", {
        "pink": background.choices === "pink",
        "blue": background.choices === "blue",
        "yellow": background.choices === "yellow",
        "green": background.choices === "green",
        "orange": background.choices === "orange",
      })}
    >
      <div className="content_wrapper">
        <div className="quote">
          <RawHtml className="quote_text" html={quote.quoteText} />
          <div className="quote_author">–{quote.author}</div>
        </div>
      </div>
    </div>
  );
};

export default BigQuote;
