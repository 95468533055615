import { FormField } from '@/types';
import React from 'react';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';

import { useSelect } from 'downshift';
import Label from '@components/Form/Label';
import { ChevronDownIcon } from '@radix-ui/react-icons';

function SelectField(props: FormField) {
  const { choices, clean_name: cleanName, label, default_value: defaultValue } = props;

  // return (
  //   <Select
  //     items={choices
  //       .split(',')
  //       .map(choice => ({ text: choice, value: choice }))}
  //     defaultValue={default_value}
  //     ariaLabel={label}
  //     label={label}
  //     id={clean_name}
  //     fluid
  //   />
  // );

  const items = choices.split(',');

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    defaultSelectedItem: defaultValue || items?.[0],
  });

  return (
    <div className='downshift-select'>
      <Label {...getLabelProps()} text={label} />
      <button className='downshift-select__button' type='button' {...getToggleButtonProps()}>
        <span>{selectedItem ?? 'keine Auswahl'}</span>{' '}
        <div className='downshift-select__chevron'>
          <ChevronDownIcon width={48} height={48} />
        </div>
      </button>
      <ul {...getMenuProps()} className='downshift-select__list'>
        {isOpen &&
          items.map((item, index) => (
            <li
              className='downshift-select__list-item'
              style={highlightedIndex === index ? { backgroundColor: '#bde4ff' } : {}}
              // eslint-disable-next-line react/no-array-index-key
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item}
            </li>
          ))}
      </ul>
      <VisuallyHidden.Root>
        <input name={cleanName} defaultValue={selectedItem ?? ''} type='hidden' />
      </VisuallyHidden.Root>
    </div>
  );
}

export default SelectField;

// return (
//   <Select
//     items={choices
//       .split(',')
//       .map(choice => ({ text: choice, value: choice }))}
//     defaultValue={default_value}
//     ariaLabel={label}
//     label={label}
//     id={clean_name}
//     fluid
//   />
// );
