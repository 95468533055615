import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

type Props = {
  legend: string;
  className?: string;
  asLabel?: boolean;
};

function Fieldset(props: PropsWithChildren<Props>) {
  const { children, legend, className, asLabel } = props;
  return (
    <fieldset className={clsx('fieldset', className, { 'fieldset--as-label': asLabel })}>
      <legend className='fieldset__legend'>{legend}</legend>
      {children}
    </fieldset>
  );
}

export default Fieldset;
