import { RequestStatus, WagtailAuthorImage, WagtailFormPage } from '@/types';
import Button from '@components/Button';
import FormFields from '@components/Form/FormFields';
import RawHtml from '@components/RawHtml';
import clsx from 'clsx';
import Image from 'next/image';
import { useState } from 'react';

type Props = {
  title: string;
  copy: string;
  image: WagtailAuthorImage;
  background: { choices: 'pink' | 'blue' | 'yellow' | 'green' | 'orange' };
  form_handle_page: WagtailFormPage;
  cta: string;
};

function ContentMarketing(props: Props) {
  const { title, copy, image, background, form_handle_page, cta } = props;

  const [status, setStatus] = useState<RequestStatus>('idle');
  // return <Debug data={props} />
  return (
    <div
      className={clsx('streamfield', 'content-marketing', 'flow', {
        pink: background.choices === 'pink',
        blue: background.choices === 'blue',
        yellow: background.choices === 'yellow',
        green: background.choices === 'green',
        orange: background.choices === 'orange',
        illustrated: image != null,
      })}
    >
      {image && (
        <div className='content-marketing-image__container'>
          <Image
            src={image.file}
            alt={image.title}
            width={400}
            height={400}
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
            }}
          />
        </div>
      )}

      <div
        className={clsx('form-copy-container', {
          pink: background.choices === 'pink',
          blue: background.choices === 'blue',
          yellow: background.choices === 'yellow',
          green: background.choices === 'green',
          orange: background.choices === 'orange',
        })}
      >
        <h3 className='content-marketing__title'>{title}</h3>
        <RawHtml html={copy} className='content-marketing__copy' />

        <form
          id='formular'
          className='form'
          onSubmit={async event => {
            event.preventDefault();

            const formData = new FormData(event.currentTarget);

            const data: {
              [key: string]: FormDataEntryValue | FormDataEntryValue[];
            } = Object.fromEntries(formData);

            form_handle_page.form_fields.forEach(field => {
              if (field.field_type !== 'checkboxes') return;
              data[field.clean_name] = formData.getAll(field.clean_name);
            });

            try {
              setStatus('loading');
              const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/v2/forms/`, {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  page: form_handle_page.id,
                  form_data: data,
                }),
              });

              if (res.ok) {
                setStatus('success');
              } else {
                setStatus('error');
              }
            } catch (error) {
              setStatus('error');
            }
          }}
        >
          <FormFields formFields={form_handle_page.form_fields} />
          {status === 'success' ? (
            <div className='message message--success'>
              <RawHtml html={form_handle_page.thank_you_text} />
            </div>
          ) : null}
          {status === 'error' ? (
            <div className='message message--error'>
              <p>Leider ist etwas schief gegangen... Bitte versuchen Sie es später erneut...</p>
              <p>
                Alternativ können Sie Ihre Anfrage auch direkt an die E-Mail-Adresse{' '}
                <a href='mailto:Hallo@werkbank.de'>Hallo@werkbank.de</a> senden.
              </p>
            </div>
          ) : null}
          <Button
            primary
            type='submit'
            disabled={status === 'loading' || status === 'success'}
            className='form__submit'
          >
            {cta}
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ContentMarketing;
