import { FormField } from '@/types';
import Input from '@components/Form/Input';
import React from 'react';

function NumberField(props: FormField) {
  const {
    label,
    clean_name: cleanName,
    help_text: helpText,
    required,
    default_value: defaultValue,
  } = props;

  return (
    <Input
      label={label}
      type='number'
      id={cleanName}
      placeholder={helpText}
      min={0}
      defaultValue={defaultValue ?? 1}
      required={required}
    />
  );
}

export default NumberField;
