import { FormField } from '@/types';
import React from 'react';

import { fields } from '@components/Form/FormFields';

type Props = {
  formFields: FormField[];
};

const extractFieldsets = (formFields: FormField[]) => {
  const groupedFields = formFields.reduce<any>((prev, curr) => {
    if (!curr.fieldset) {
      return { ...prev, [curr.clean_name]: curr };
    }

    return {
      ...prev,
      [curr.fieldset]: {
        field_type: 'fieldset',
        legend: curr.fieldset,
        sort_order: Math.min(prev[curr.fieldset]?.sort_order ?? Infinity, curr.sort_order),
        fields: [...(prev[curr.fieldset]?.fields ?? []), curr],
      },
    };
  }, {});

  return Object.values<FormField>(groupedFields);
};

function FormFields(props: Props) {
  const { formFields } = props;

  const formFieldsByFieldsets = extractFieldsets(
    formFields.sort((a, b) => a.sort_order - b.sort_order),
  );

  return (
    <>
      {formFieldsByFieldsets.map((field, index) => {
        const Component = fields[field.field_type];

        if (!Component) {
          return (
            <h2 key={field.id ?? `fieldset-${index}`}>
              Unknown form field <strong>{field.field_type}</strong>
            </h2>
          );
        }

        return <Component key={field.id ?? `fieldset-${index}`} {...field} />;
      })}

      <div style={{ overflow: 'hidden', width: 0, height: 0 }} aria-hidden='true'>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor='decoy-comments'>Leave this blank if you&apos;re a human</label>
        <textarea rows={1} name='decoy-comments' id='decoy-comments' />
      </div>
    </>
  );
}

export default FormFields;
