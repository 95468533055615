import { WagtailDocument } from "@/types";
import clsx from "clsx";
import Link from "next/link";
import Draft from '../../styles/assets/images/draft.svg';
import ImageLogo from '../../styles/assets/images/imagesmode.svg';
import PDF from '../../styles/assets/images/picture_as_pdf.svg';
import TableLogo from '../../styles/assets/images/table_rows_narrow.svg';


type Props = {
    background: { choices: "pink" | "blue" | "yellow" | "green" | "orange" };
    file: WagtailDocument;
    download_text: String;
};

function Download(props: Props) {
    const { background, file, download_text } = props;

    const fileType = file.file.substring(file.file.lastIndexOf('.'), file.file.length);

    function ChooseIcon(type: string) {
        let icon;
        switch (type) {
            case '.pdf':
                icon = <PDF />
                break;
            case '.xls':
                icon = <TableLogo />
                break;
            case '.png':
                icon = <ImageLogo />
                break;
            default:
                icon = <Draft />
        }

        return icon;

    }

    return (
        <div
            className={clsx("streamfield", "download", "flow", {
                "pink": background.choices === "pink",
                "blue": background.choices === "blue",
                "yellow": background.choices === "yellow",
                "green": background.choices === "green",
                "orange": background.choices === "orange",
            })}>
            <Link href={file.file} target="blank"
                className='download__link'>
                <div className="download__svg">

                    {ChooseIcon(fileType)}
                </div>
                <div className="download__title">Download</div>
                <div className="download__text">{download_text != null && download_text !== "" ? download_text : file.title}</div>
            </Link>
        </div>
    );
};

export default Download;
