import { DownloadCard } from "@/types";
import Download from "@streamfields/Download/Download";


type Props = {
    downloads: DownloadCard[];
};

function DownloadGroup(props: Props) {
    const { downloads } = props;
    return (
        <div className="streamfield flow downloads__container">

            {downloads?.map(download => (
                <Download
                    key={downloads.indexOf(download)}
                    background={download.background}
                    file={download.file}
                    download_text={download.download_text} />
            ))}
        </div>
    );
};

export default DownloadGroup;
