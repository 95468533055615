import React from 'react';
import streamfields from '@/streamfields';

import type { Streamfield } from '@/types';

type Props = {
  fields: Streamfield[];
};

function Streamfields(props: Props) {
  const { fields } = props;

  return (
    <>
      {fields.map(field => {
        const Component = streamfields[field.type];
        if (!Component) {
          return (
            <h2 key={field.id}>
              Component <strong>{field.type}</strong> not found
            </h2>
          );
        }

        return (
          <Component
            key={field.id}
            {...(typeof field.value === 'string' ? { value: field.value } : field.value)}
          />
        );
      })}
    </>
  );
}

export default Streamfields;
