/* eslint-disable import/no-extraneous-dependencies */
import { Highlight, themes } from "prism-react-renderer";

type Props = {
  language: string;
  code: string;
}

function CodeBlock(props: Props) {


  const { language, code } = props;

  return (
    <div className='streamfield code-block flow'>
      <Highlight
        theme={themes.shadesOfPurple}
        code={code}
        language={language}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div key={tokens.indexOf(line)} {...getLineProps({ line })}>
                <span className="code__line-number">{i + 1}  </span>
                {line.map((token) => (
                  <span key={line.indexOf(token)} {...getTokenProps({ token })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>

    </div>
  );
}

export default CodeBlock;
