import { FormField } from '@/types';
import Input from '@components/Form/Input';
import React from 'react';

function HiddenField(props: FormField) {
  const { label, clean_name: cleanName, help_text: helpText } = props;

  return <Input label={label} type='hidden' id={cleanName} placeholder={helpText} />;
}

export default HiddenField;
