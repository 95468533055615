import Link from "next/link";

type Props = {
    author: string;
    title: string;
    source_url: string;
};

function Source(props: Props) {

    const { author, title, source_url } = props

    return (
        <div className="source__wrapper">
            <Link
                href={source_url}
                className='source__link'
                target="_blank">
                <div className="source__author">{author}: </div>
                <div className="source__title">{title}</div>
            </Link>
        </div>
    );
};

export default Source;
