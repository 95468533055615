import EmailField from './EmailField';
import SelectField from './SelectField';
import FormFields from './FormFields';
import DateField from './DateField';
import DateTimeField from './DateTimeField';
import HiddenField from './HiddenField';
import SingleLineField from './SingleLineField';
import NumberField from './NumberField';
import CheckBoxField from './CheckBoxField';
import MultiLineField from './MultiLineField';
import RadioField from './RadioField';
import CheckBoxesField from './CheckBoxesField';
import TimeField from './TimeField';
import FieldsetField from './FieldsetField';

const fields = {
  checkbox: CheckBoxField,
  checkboxes: CheckBoxesField,
  date: DateField,
  dropdown: SelectField,
  datetime: DateTimeField,
  email: EmailField,
  hidden: HiddenField,
  multiline: MultiLineField,
  multiselect: () => <p>multiselect not implemented</p>,
  number: NumberField,
  radio: RadioField,
  singleline: SingleLineField,
  time: TimeField,
  fieldset: FieldsetField,
};

export type FormFieldTypes = keyof typeof fields;

export { fields };

export default FormFields;
